import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { CatchAllErrorFallback } from './components/ErrorFallbacks/CatchAllErrorFallback';
import { AppRouter } from './pages/AppRouter';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { ScrollToTop } from './components/ScrollToTop';

import './index.scss';

export const App: React.FC = () => {
    return (
        <ErrorBoundary FallbackComponent={CatchAllErrorFallback} onError={() => {}}>
            <HelmetProvider>
                <Helmet titleTemplate="%s | Becoming Braga" defaultTitle="Becoming Braga">
                    <meta name="description" content="Becoming Braga" />
                </Helmet>
                <BrowserRouter>
                    <ScrollToTop />
                    <Header />
                    <AppRouter />
                </BrowserRouter>
                <Footer />
            </HelmetProvider>
        </ErrorBoundary>
    );
};
