/**
 * Asynchronously loads the component
 */

import { lazyLoad } from '../../../utils/loadable';

export const WeddingPartyPage = lazyLoad(
    () => import('./index'),
    (module) => module.WeddingPartyPage,
);
