import { HeaderTitle } from '../HeaderTitle';
import { Link } from '@mui/material';
import { NavLink } from "react-router-dom";
import { AppRoutes } from '/Users/kbraga/Documents/Dev/becoming-braga-ts/src/app/pages/appRoutes';

import './index.scss';

interface DesktopHeaderProps {
    animateClass: string;
    isActiveClass: (route: string) => string;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ animateClass, isActiveClass }) => (
    <div className="desktop-header__wrapper header">
        <div className="desktop-header">
            <div className={`desktop-header__title ${animateClass}`}>
                <HeaderTitle />
            </div>
            <div className={`desktop-header__links ${animateClass}`}>
                <NavLink
                    to={AppRoutes.Home}
                    className={`desktop-header__links-link ${ isActiveClass(AppRoutes.Home)}`}
                >
                    Home
                </NavLink>
                <NavLink
                    to={AppRoutes.RSVP}
                    className={`desktop-header__links-link ${ isActiveClass(AppRoutes.RSVP)}`}
                >
                    RSVP
                </NavLink>
                <NavLink
                    to={AppRoutes.Schedule}
                    className={`desktop-header__links-link ${ isActiveClass(AppRoutes.Schedule)}`}
                >
                    Schedule
                </NavLink>
                <NavLink
                    to={AppRoutes.Accommodations}
                    className={`desktop-header__links-link ${ isActiveClass(AppRoutes.Accommodations)}`}
                >
                    Accommodations
                </NavLink>
                <NavLink
                    to={AppRoutes.Venue}
                    className={`desktop-header__links-link ${ isActiveClass(AppRoutes.Venue)}`}
                >
                    Venue
                </NavLink>
                <NavLink
                    to={AppRoutes.OurStory}
                    className={`desktop-header__links-link ${ isActiveClass(AppRoutes.OurStory)}`}
                >
                    Our Story
                </NavLink>
                <NavLink
                    to={AppRoutes.WeddingParty}
                    className={`desktop-header__links-link ${ isActiveClass(AppRoutes.WeddingParty)}`}
                >
                    Wedding Party
                </NavLink>
                <Link
                    href="https://www.zola.com/registry/kyleandallisonjune17"
                    target="_blank"
                    className={`desktop-header__links-link is-external ${ isActiveClass(AppRoutes.Registry)}`}
                >
                    Registry
                </Link>
                <NavLink
                    to={AppRoutes.FAQs}
                    className={`desktop-header__links-link ${ isActiveClass(AppRoutes.FAQs)}`}
                >
                    FAQs
                </NavLink>
            </div>
        </div>
    </div>
);
