// @ts-nocheck
import { generatePath as internalGeneratePath, matchPath } from 'react-router-dom';
import { ExtractRouteParams } from './types.ts';

/**
 * appRoutes.ts
 *
 * `AppRoutes` contains the constants of application routes.
 *
 * The template strings like `:id` can be replaced using `generatePath` function.
 *
 * For `buildRoute`, Typescript will automagically/dynamically complete the parameters object.
 *
 * Example:
 *
 * buildRoute(AppRoutes.CategoryMarkets, { id: '123' })
 */

const AppRoutes = {
  Home: '/',
  Accommodations:'/accommodations',
  RSVP: '/rsvp',
  Schedule: '/schedule',
  Venue: '/venue',
  OurStory: '/our-story',
  WeddingParty: '/wedding-party',
  Registry: '/registry',
  FAQs: '/faqs',
  NotFound: '/404',
} as const;

export type AppRoute = typeof AppRoutes[keyof typeof AppRoutes];

function buildRoute<T extends string = AppRoute>(path: T, params?: ExtractRouteParams<T>) {
  return internalGeneratePath<T>(path, params);
}

function deriveRoute(pathname: string): AppRoute {
  const paths = Object.values(AppRoutes);
  const path = paths.find(
    (p: string) =>
      matchPath(pathname, {
        path: p,
        exact: true,
        strict: true,
      })?.path,
  );
  return path || AppRoutes.NotFound;
    }

export { AppRoutes, buildRoute, deriveRoute };
