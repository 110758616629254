/**
 * Asynchronously loads the component
 */

import { lazyLoad } from '../../../utils/loadable';

export const RegistryPage = lazyLoad(
    () => import('./index'),
    (module) => module.RegistryPage,
);
