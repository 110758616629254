/**
 * Asynchronously loads the component
 */

import { lazyLoad } from '../../../utils/loadable';

export const FAQsPage = lazyLoad(
    () => import('./index'),
    (module) => module.FAQsPage,
);
