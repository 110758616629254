import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { TransparentHeader } from './TransparentHeader';
import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';
import { AppRoutes } from '/Users/kbraga/Documents/Dev/becoming-braga-ts/src/app/pages/appRoutes';

import './index.scss';

export const Header = () => {
    const [isTransparentHeader, setIsTransparentHeader] = useState(true);
    const [forceStaticHeader, setForceStaticHeader] = useState(false);
    const [hasSeenFullHeader, setHasSeenFullHeader] = useState(false);
    const [animateClass, setAnimateClass] = useState('');
    const { pathname } = useLocation();
    
    const showTransparentHeader = !hasSeenFullHeader && !forceStaticHeader && isTransparentHeader;
    const showFullHeader =  hasSeenFullHeader || (!isTransparentHeader || forceStaticHeader);

    const isActiveClass = (route: string) => route === window.location.pathname ? 'active' : '';

    useEffect(() => {
        if (pathname === AppRoutes.Home) {
            // animate header if home page
            setAnimateClass('animate');

            // reset transparent header
            setForceStaticHeader(false);

            // listen for scroll to transition header
            window.addEventListener('scroll', () => {
                const TOP_PAGE_CUTOFF = 30;
    
                // show transparent header if at top of home page
                if (window.scrollY <= TOP_PAGE_CUTOFF && !hasSeenFullHeader) {
                    setIsTransparentHeader(true);
                } else {
                    // transition to full header after scroll
                    setIsTransparentHeader(false);
                    setHasSeenFullHeader(true);
                }
            });
        } else {
            setForceStaticHeader(true)
        }
    }, [pathname, hasSeenFullHeader]);

    return (
        <div className="header">
            {showTransparentHeader &&
                <TransparentHeader/>
            }
            {showFullHeader &&
                (
                    <>
                        <DesktopHeader animateClass={animateClass} isActiveClass={isActiveClass}/>
                        <MobileHeader isActiveClass={isActiveClass} />
                    </>
                    

                )
                
           }
        </div>
    );
};