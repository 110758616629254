/**
 * Asynchronously loads the component
 */

import { lazyLoad } from '../../../utils/loadable';

export const SchedulePage = lazyLoad(
    () => import('./index'),
    (module) => module.SchedulePage,
);
