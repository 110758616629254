/**
 * Asynchronously loads the component
 */

import { lazyLoad } from '../../../utils/loadable';

export const OurStoryPage = lazyLoad(
    () => import('./index'),
    (module) => module.OurStoryPage,
);
