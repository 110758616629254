// @ts-nocheck
import { Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { CatchAllErrorFallback } from '../components/ErrorFallbacks/CatchAllErrorFallback';
import { AppRoutes } from '/Users/kbraga/Documents/Dev/becoming-braga-ts/src/app/pages/appRoutes';
import { HomePage } from './Home/Loadable';
import { RSVPPage } from './RSVP/Loadable';
import { NotFoundPage } from './NotFound/Loadable';
import { AccommodationsPage } from './Accommodations/Loadable';
import { SchedulePage } from './Schedule/Loadable';
import { VenuePage } from './Venue/Loadable';
import { OurStoryPage } from './OurStory/Loadable';
import { WeddingPartyPage } from './WeddingParty/Loadable';
import { RegistryPage } from './Registry/Loadable';
import { FAQsPage } from './FAQs/Loadable';

export const AppRouter = () => (
  <ErrorBoundary FallbackComponent={CatchAllErrorFallback} onError={() => {}}>
    <Routes>
      <Route path={AppRoutes.Home} element={<HomePage />} />
      <Route path={AppRoutes.RSVP} element={<RSVPPage />} />
      <Route path={AppRoutes.Schedule} element={<SchedulePage />} />
      <Route path={AppRoutes.Accommodations} element={<AccommodationsPage />} />
      <Route path={AppRoutes.Venue} element={<VenuePage />} />
      <Route path={AppRoutes.OurStory} element={<OurStoryPage />} />
      <Route path={AppRoutes.WeddingParty} element={<WeddingPartyPage />} />
      <Route path={AppRoutes.Registry} element={<RegistryPage />} />
      <Route path={AppRoutes.FAQs} element={<FAQsPage />} />
      <Route path={'*'} element={<NotFoundPage />} />
    </Routes>
  </ErrorBoundary>
);
