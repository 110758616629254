import { useState } from "react";
import { HeaderTitle } from '../HeaderTitle';
import { NavLink } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import CelebrationTwoToneIcon from '@mui/icons-material/CelebrationTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import HelpCenterTwoToneIcon from '@mui/icons-material/HelpCenterTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import {
    SwipeableDrawer,
    List,
    ListItem,
    Box,
    Divider,
    Link,
} from '@mui/material';
import { AppRoutes } from '/Users/kbraga/Documents/Dev/becoming-braga-ts/src/app/pages/appRoutes';

import './index.scss';

interface MobileHeaderProps {
    isActiveClass: (route: string) => string;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ isActiveClass }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="mobile-header__wrapper header">
            <div className="mobile-header">
                <div className="mobile-header__links">
                    <SwipeableDrawer
                        anchor={'left'}
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                        onOpen={() => setIsOpen(true)}
                    >
                        <Box
                            sx={{ width: 250 }}
                            role="presentation"
                            onClick={() => setIsOpen(false)}
                            onKeyDown={() => setIsOpen(false)}
                        >
                            <div className="mobile-header__top">
                                <CloseTwoToneIcon 
                                    className="mobile-header__top-icon"
                                    onClick={() => setIsOpen(false)}
                                />
                                <NavLink
                                    to={AppRoutes.Home}
                                    className={`mobile-header__links-icon`}
                                    onClick={() => setIsOpen(false)}
                                >
                                    <div className="mobile-header__links-icon-logo" />
                                </NavLink>
                            </div>
                            <Divider className="mobile-header__links-divider"/>
                            <List>
                                <ListItem key={'Home'} disablePadding>
                                    <NavLink
                                        className={`mobile-header__links-row ${ isActiveClass(AppRoutes.Home)}`}
                                        to={AppRoutes.Home}
                                        onClick={() => setIsOpen(false)}
                                    >
                                        <div className={`mobile-header__links-row-icon ${ isActiveClass(AppRoutes.Home)}`}>
                                            <HomeTwoToneIcon />
                                        </div>
                                        <div className={`mobile-header__links-row-link ${ isActiveClass(AppRoutes.Home)}`}>
                                            Home
                                        </div>
                                    </NavLink>
                                </ListItem>
                                <ListItem key={'RSVP'} disablePadding>
                                    <NavLink
                                        className={`mobile-header__links-row ${ isActiveClass(AppRoutes.RSVP)}`}
                                        to={AppRoutes.RSVP}
                                        onClick={() => setIsOpen(false)}
                                    >
                                        <div className={`mobile-header__links-row-icon ${ isActiveClass(AppRoutes.RSVP)}`}>
                                            <EmailTwoToneIcon />
                                        </div>
                                        <div className={`mobile-header__links-row-link ${ isActiveClass(AppRoutes.RSVP)}`}>
                                            RSVP
                                        </div>
                                    </NavLink>
                                </ListItem>
                                <ListItem key={'Schedule'} disablePadding>
                                    <NavLink
                                        className={`mobile-header__links-row ${ isActiveClass(AppRoutes.Schedule)}`}
                                        to={AppRoutes.Schedule}
                                        onClick={() => setIsOpen(false)}
                                    >
                                        <div className={`mobile-header__links-row-icon ${ isActiveClass(AppRoutes.Schedule)}`}>
                                            <CalendarMonthTwoToneIcon />
                                        </div>
                                        <div className={`mobile-header__links-row-link ${ isActiveClass(AppRoutes.Schedule)}`}>
                                            Schedule
                                        </div>
                                    </NavLink>
                                </ListItem>
                                <ListItem key={'Accommodations'} disablePadding>
                                    <NavLink
                                        className={`mobile-header__links-row ${ isActiveClass(AppRoutes.Accommodations)}`}
                                        to={AppRoutes.Accommodations}
                                        onClick={() => setIsOpen(false)}
                                    >
                                        <div className={`mobile-header__links-row-icon ${ isActiveClass(AppRoutes.Accommodations)}`}>
                                            <HotelTwoToneIcon />
                                        </div>
                                        <div className={`mobile-header__links-row-link ${ isActiveClass(AppRoutes.Accommodations)}`}>
                                            Accommodations
                                        </div>
                                    </NavLink>
                                </ListItem>
                                <ListItem key={'Venue'} disablePadding>
                                    <NavLink
                                        className={`mobile-header__links-row ${ isActiveClass(AppRoutes.Venue)}`}
                                        to={AppRoutes.Venue}
                                        onClick={() => setIsOpen(false)}
                                    >
                                        <div className={`mobile-header__links-row-icon ${ isActiveClass(AppRoutes.Venue)}`}>
                                            <CelebrationTwoToneIcon />
                                        </div>
                                        <div className={`mobile-header__links-row-link ${ isActiveClass(AppRoutes.Venue)}`}>
                                            Venue
                                        </div>
                                    </NavLink>
                                </ListItem>
                                <ListItem key={'OurStory'} disablePadding>
                                    <NavLink
                                        className={`mobile-header__links-row ${ isActiveClass(AppRoutes.OurStory)}`}
                                        to={AppRoutes.OurStory}
                                        onClick={() => setIsOpen(false)}
                                    >
                                        <div className={`mobile-header__links-row-icon ${ isActiveClass(AppRoutes.OurStory)}`}>
                                            <FavoriteTwoToneIcon />
                                        </div>
                                        <div className={`mobile-header__links-row-link ${ isActiveClass(AppRoutes.OurStory)}`}>
                                            Our Story
                                        </div>
                                    </NavLink>
                                </ListItem>
                                <ListItem key={'WeddingParty'} disablePadding>
                                    <NavLink
                                        className={`mobile-header__links-row ${ isActiveClass(AppRoutes.WeddingParty)}`}
                                        to={AppRoutes.WeddingParty}
                                        onClick={() => setIsOpen(false)}
                                    >
                                        <div className={`mobile-header__links-row-icon ${ isActiveClass(AppRoutes.WeddingParty)}`}>
                                            <GroupsTwoToneIcon />
                                        </div>
                                        <div className={`mobile-header__links-row-link ${ isActiveClass(AppRoutes.WeddingParty)}`}>
                                            Wedding Party
                                        </div>
                                    </NavLink>
                                </ListItem>
                                <ListItem key={'Registry'} disablePadding>
                                    <Link
                                        className={`mobile-header__links-row is-external ${ isActiveClass(AppRoutes.Registry)}`}
                                        href="https://www.zola.com/registry/kyleandallisonjune17"
                                        target="_blank"
                                    >
                                        <div className={`mobile-header__links-row-icon ${ isActiveClass(AppRoutes.Registry)}`}>
                                            <ListAltTwoToneIcon />
                                        </div>
                                        <div className={`mobile-header__links-row-link ${ isActiveClass(AppRoutes.Registry)}`}>
                                            Registry
                                        </div>
                                    </Link>
                                </ListItem>
                                <ListItem key={'FAQs'} disablePadding>
                                    <NavLink
                                        className={`mobile-header__links-row ${ isActiveClass(AppRoutes.FAQs)}`}
                                        to={AppRoutes.FAQs}
                                        onClick={() => setIsOpen(false)}
                                    >
                                        <div className={`mobile-header__links-row-icon ${ isActiveClass(AppRoutes.FAQs)}`}>
                                            <HelpCenterTwoToneIcon />
                                        </div>
                                        <div className={`mobile-header__links-row-link ${ isActiveClass(AppRoutes.FAQs)}`}>
                                            FAQs
                                        </div>
                                    </NavLink>
                                </ListItem>
                            </List>
                        </Box>
                    </SwipeableDrawer>
                </div>
                <div className={`mobile-header__menu`}  onClick={() => setIsOpen(true)}>
                    <MenuIcon />
                </div>
                <div className={`mobile-header__title`}>
                    <HeaderTitle />
                </div>
            </div>
        </div>
    );
}