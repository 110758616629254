import { NavLink } from "react-router-dom";
import { AppRoutes } from '/Users/kbraga/Documents/Dev/becoming-braga-ts/src/app/pages/appRoutes';

import './index.scss';


export const HeaderTitle = () => (
    <NavLink
        style={{ textDecoration: 'none' }}
        to={AppRoutes.Home}
        className="header-title__wrapper"
    >
        <div className="header-title__logo" />
        <div className="header-title__text" >
            <div className="header-title__text-becoming" >
                Becoming
            </div>
            <div className="header-title__text-braga" >
                Braga
            </div>
        </div> 
    </NavLink>
);