import './index.scss';

export const Footer = () => {
    return (
        <div className="footer__wrapper">
            <div className="footer__content">
                <div className="footer__content-help" >
                    Need help? Email <a href = "mailto: becoming.braga@gmail.com" className="footer__content-help-link">becoming.braga@gmail.com</a>
                </div>
                <div className="footer__content-copy" >
                    &copy; 2022 KB CODES LLC
                </div>
            </div>
        </div>
    );
};